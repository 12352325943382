import {
  Icon,
  SideNav as SideNavOriginal,
  SideNavItem,
} from "@dws/dws-styleguide";
import logo from "../../public/img/dws-logo.png";
import { Link } from "@reach/router";
import { LogoutButton } from "@dws/dws-auth";
import { useTranslation } from "@dws/dws-profile";

const SideNav = () => {
  const { t } = useTranslation();

  return (
    <SideNavOriginal
      id="sidenav-gen"
      options={{
        draggable: true,
      }}
      trigger={
        <a href="#!" className="brand-logo left">
          <Icon>menu</Icon>
        </a>
      }>
      <SideNavItem href="#">
        <Icon className="mr1 sidenav-close">close</Icon>
        <img src={logo} height={64} className="top-bar-logo" />
      </SideNavItem>
      <SideNavItem divider />
      <SideNavItem
        className={window.location.pathname === "/main" ? "active" : ""}>
        <Link to="/main" className="p0">
          <div className="purple-bar"></div>
          {t("general.home")}
        </Link>
      </SideNavItem>
      <SideNavItem
        className={window.location.pathname === "/shortcuts" ? "active" : ""}>
        <Link to="/shortcuts" className="p0">
          <div className="purple-bar"></div>
          {t("general.apps")}
        </Link>
      </SideNavItem>
      <SideNavItem
        className={window.location.pathname === "/profile" ? "active" : ""}>
        <Link to="/profile" className="p0">
          <div className="purple-bar"></div>
          {t("general.configurations")}
        </Link>
      </SideNavItem>
      <LogoutButton />
    </SideNavOriginal>
  );
};

export default SideNav;
