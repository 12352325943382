import React, { useEffect, useState } from "react";
import { Link, Redirect } from "@reach/router";
import SideNav from "./SideNav";
import { getAccount } from "@dws/dws-auth";
import logo from "../../public/img/dws-logo.png";

interface AccountInfo {
  name: string;
  idTokenClaims: {
    exp: number;
  };
}

type TopbarProps = Record<string, unknown> & { children?: React.ReactNode };

export function Topbar(_: TopbarProps) {
  const [redirectNow, setRedirectNow] = useState<boolean>(false);
  const account: AccountInfo = getAccount();

  useEffect(() => {
    const tokenExpired = () => {
      const tokenExp = account?.idTokenClaims?.exp;
      const now = Date.now() / 1000;
      return !account || !tokenExp || tokenExp - now < 1;
    };

    if (!redirectNow && tokenExpired()) {
      setRedirectNow(true);
    }
  }, [account, redirectNow]);

  if (redirectNow) return <Redirect to={"/"} />;

  return (
    <div className="container-topbar">
      <nav>
        <div className="nav-wrapper">
          <SideNav />
          <div className="nav-wrapper-items">
            <Link to="/main" className="p0">
              <img
                src={logo}
                height={64}
                className="top-bar-logo ml-6 pointer"
              />
            </Link>
            <div className="brand-logo right">
              <div data-testid="avatar" className="header-avatar center">
                <span data-testid="initial">
                  {account?.name ? account.name.substring(0, 1) : "?"}
                </span>
              </div>
              <span data-testid="name" className="header-text">
                {account?.name || ""}
              </span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
